import React from 'react'
import Layouts from './Layouts'
import SubHeader from "../components/SubHeader";
import banner from "../images/about_history.svg";
import { Link } from 'react-router-dom';

const TermsConditions = () => {
    return (
        <>
            <Layouts>

                <SubHeader banner={banner} heading="Terms & Conditions" />


                <section className="about-section spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="term-title">
                                    <h3>Welcome to Shivay Adventure                                    </h3>
                                    <p className="f-para">Welcome to Shivay Adventure! These terms and conditions govern your use of our website, shivayadventure.com, and the services we provide, including Maha Kumbh Mela tour packages and related offerings. By accessing our website or booking any services, you agree to abide by these terms and conditions.
                                        <br></br>
                                        If you disagree with any part of these terms, you must not use our website or services.</p>
                                    <h3>Services Offered                                    </h3>
                                    <p>We specialize in providing tour packages for the Maha Kumbh Mela, including but not limited to accommodation booking, transportation arrangements, guided tours, photography tours and special event access like Akhara visits or Naga experience. Our services are detailed on our website as well as on the itineraries shared with you during booking, and we reserve the right to modify or discontinue any service at our discretion.</p>
                                    <h3>Booking and Payments                                    </h3>
                                    <ul>
                                        <li>All bookings must be made through our website or via direct contact with our official representatives.</li>
                                        <li>A deposit is required at the time of booking to secure your package, with the balance due as specified in your booking confirmation email and invoice.</li>
                                        <li>Accommodations can be booked only on our camps booking platform “shivayadventure.com”. The details and amenities mentioned there will be considered final.</li>
                                        <li>Payments can be made via credit/debit card, bank transfer, or other payment methods as indicated on our website.</li>
                                    </ul>


                                    <h3>Cancellations and Refunds</h3>
                                    <ul>
                                        <li>Cancellations must be made in writing and sent to our customer service team.</li>
                                        <li>Cancellation charges will apply as per our <Link to="/refundpolicy">Refunds and Cancellation Policy</Link> available on our website.</li>
                                        <li>Refunds will be processed within 30-45 days of the cancellation request, using the original method of payment.</li>
                                    </ul>
                                    <h3>Liability and Insurance</h3>

                                    <ul>
                                        <li>We act only as an agent for the providers of accommodation, transportation, and other services connected to the tours. As such, we are not liable for any loss, damage, accident, delay, or inconvenience caused by these third-party providers.
                                        </li>
                                        <li>We strongly recommend that all travelers obtain comprehensive travel insurance covering cancellations, medical expenses, personal accident, personal baggage, and liability before traveling.</li>
                                    </ul>
                                    <h3>Conduct and Behavior</h3>
                                    <ul>
                                        <li>
                                            Participants are expected to conduct themselves in a manner that is respectful of local customs, traditions, and laws.</li>
                                        <li>We reserve the right to refuse service or terminate a tour for any participant whose behavior is deemed inappropriate or disruptive to the group without any refund.

                                        </li>
                                    </ul>
                                    <h3>Changes to Terms and Conditions
                                    </h3>
                                    <p>We reserve the right to amend these terms and conditions at any time. Any changes will be effective immediately upon posting on our website. It is your responsibility to check these terms and conditions periodically for changes.

                                    </p>
                                    <h3>Governing Law
                                    </h3>
                                    <p>These terms and conditions are governed by and construed in accordance with the laws of Uttar Pradesh, India, and you irrevocably submit to the exclusive jurisdiction of the courts in that location.

                                    </p>
                                    <h3>Contact Us
                                    </h3>
                                    <p>If you have any questions about these terms and conditions, please contact us via the contact information provided on our website. By booking a tour package with us, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.

                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>


            </Layouts>
        </>
    )
}

export default TermsConditions
