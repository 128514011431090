import React from 'react'
import footerimg from "../images/logof.png"
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
    <div className='footer_brd'></div>
    <footer class="footer-section foofter_bg">
        <div class="container">
            
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer-text">
                        <div class="ft-logo"> 
                            <img src={footerimg} style={{maxWidth:"230px"}} />
                        </div>
                        <ul>
                            <li><Link to="/">Home</Link></li> 
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/bath-date">Bathing Dates</Link></li>
                            <li><Link to="/booking">Book Tent</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                            <li><Link to="/refundpolicy">Refund Policy</Link></li>
                            <li><Link to="/terms-conditions"> Terms and Conditions</Link></li>
                            <li><Link to="/privacypolicy">Privacy Policy </Link></li>
                        </ul>
                        
                        <div class="ft-social">
                            <Link to=""><i class="fa fa-facebook"></i></Link>
                            <Link to=""><i class="fa fa-twitter"></i></Link>
                            <Link to=""><i class="fa fa-linkedin"></i></Link>
                            <Link to="https://www.instagram.com/shivay_adventure/" target='_blank'><i class="fa fa-instagram"></i></Link>
                            <Link to=""><i class="fa fa-youtube-play"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    
    </>
  )
}

export default Footer