import React from 'react'

const SubHeader = ({banner,heading}) => {
  return (
   <>
   
   <section className="breadcrumb-section sub_bg" style={{background: `url(${`${banner}`})`}}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="breadcrumb-text">
                        <h2>{heading}</h2>
                        <div className="bt-option">
                            <a href="#">Home</a>
                            <span>{heading}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   
   </>
  )
}

export default SubHeader