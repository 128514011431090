import React, { useState } from 'react'
import logo from "../images/logo.png"
import { Link, useLocation } from 'react-router-dom'
const Header = () => {

const [menu, setMenu]= useState(false)
    const location = useLocation();

    const pageName = location.pathname === '/' ? 'home' : location.pathname.substring(1);
    return (
        <>

            <header className="header-section">
                <div className="container">
                    <div className="logo">
                        <Link to="/">
                            <img src={logo} alt="" />
                        </Link>
                    </div>
                    <div className={menu == true ? "nav-menu activemenu" : "nav-menu "}>
                        <nav className="mainmenu mobile-menu">
                            <ul>
                                <li className={pageName == "home" ? "active" : " "}><Link to="/">Home</Link></li>
                                <li className={pageName == "about" ? "active" : " "}><Link to="/about">About</Link></li>
                                {/* <li><Link to="./speaker.html">Speakers</Link>
                            <ul className="dropdown">
                                <li><Link to="#">Jayden</Link></li>
                                <li><Link to="#">Sara</Link></li>
                                <li><Link to="#">Emma</Link></li>
                                <li><Link to="#">Harriet</Link></li>
                            </ul>
                        </li> */}
                                <li className={pageName == "bath-date" ? "active" : " "}><Link to="/bath-date">Bathing Dates</Link></li>
                            </ul>
                        </nav>
                        <Link to="/booking" className="primary-btn top-btn"><i className="fa fa-ticket"></i> Book Now</Link>
                        <Link to="/contact" className="primary-btn contact-btn"><i className="fa fa-phone"></i> Contact Us</Link>
                    </div>
                    <div id="mobile-menu-wrap" onClick={()=>setMenu(!menu)}>{menu == true ? <i className='fa fa-close'></i> : <i className='fa fa-align-justify'></i>}</div>
                </div>
            </header>

        </>
    )
}

export default Header