import React from 'react'
import Layouts from './Layouts'
import SubHeader from '../components/SubHeader'
import banner from '../images/sub-aboutbanner.jpg'
import Aboutimg from '../images/about-img.png'

const About = () => {
  return (
   <Layouts>
    <SubHeader banner={banner} heading="About" />

    <section className="about-section spad">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <h2>What is Maha Kumbh Mela and why is it celebrated?    </h2>
                        <p className="f-para">It gives us immense pleasure to introduce ourselves as "Sangam Services"( a company owned and managed by Ex servicemen of Indian Air Force with proven record of Honesty, faithfulnes and Exemplary discipline), to enrich your experiences by providing you all the comforts and luxuries you desire. "Sangam Services" is a company having its base at Prayagraj (Allahabad), Uttar Pradesh, in India, dealing with hospitality services delivered by a well experienced and motivated team of adept associates.<br></br>

You need to just confirm your booking on-line and forget all your apprehensions and doubts after informing us your date of arrival. Our representatives will ensure your safe and sound stay and make you to feel the rich taste of Indian hospitality.

We possess the versatile experience and power of successful hosting of Maha Kumbh Camp in year 2013 and 2019. We have an innovative idea to change the concept of camping solution especially during Kumbh Mela, Maha Kumbh and other such type of enormous confluence and grand gathering. We have brought advancement and revolution in the quality of camps. The material used is Fire proof, Water proof, Termite resistance, Weather proof and Eco friendly. We don’t have camps made up of clothes as they are vulnerable to various threats and can be easily torn. Our camps are completely safe against natural calamities such as earthquake, storms, heavy rain fall and hail. We provide full protection from any natural of man-monitored threats.

Keeping in view the safety and security of our guests and their valuable belongings, our camp is well equipped with individual doors with locking facility. In addition to this, our camps are equipped with many comforts like running hot-water and WiFi etc. to make your stay memorable for ever.</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="about-pic">
                        <img src={Aboutimg} alt="" />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="about-text">
                        <h3>                        Significance of Maha Kumbh Mela 2025     </h3>
                        <p>Kumbh Mela, in Hinduism, is a religious pilgrimage that is celebrated four times over a course of 12 years. The geographical location of Kumbh Mela spans over four locations in India and the Mela site keeps rotating between one of the four pilgrimages on four sacred rivers as listed below:</p>
                         
                        <ul>
                        <li><span className="icon_check"></span>Haridwar (Ganges River)</li>
                          <li><span className="icon_check"></span>In Haridwar, Uttarakhand, on the banks of Ganges</li>
                          <li><span className="icon_check"></span>In Ujjain, Madhya Pradesh on the banks of Shipra</li>
                          <li><span className="icon_check"></span>In Nashik, Maharashtra on the banks of Godavari</li>
                          <li><span className="icon_check"></span>In Prayagraj, Uttar Pradesh, at the confluence of the Ganges, the Yamuna, and the mythical invisible Sarasvati</li>
                            <li><span className="icon_check"></span> Save Money                            </li>
                            <li><span className="icon_check"></span> Safety and Security                            </li>
                            <li><span className="icon_check"></span> Total Support                            </li>
                            <li><span className="icon_check"></span> Connecting with God                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
   </Layouts>
  )
}

export default About