import React from 'react'
import bannerImage from "../images/banner.png"
import './../../src/App.css'

const HomeSlider = () => {
  return (
    <>
      <section class="hero-section set-bg banner h-400" data-setbg={bannerImage}>
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <div class="hero-text"> 
                        <h2>Maha Kumbh Mela <b>2025</b>   </h2>
                        <h4> 13 January - 26 February                        </h4>
                        {/* <a href="#" class="primary-btn">Buy Ticket</a> */}
                    </div>
                </div>

            </div>
        </div>
    </section>
    
    </>
  )
}

export default HomeSlider