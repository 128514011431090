import React from 'react'
import Layouts from './Layouts'
import SubHeader from '../components/SubHeader'
import banner from "../images/banner1.png"
import bathimg from "../images/bath-img.png" 
import { Link } from 'react-router-dom'

const Bath = () => {
  return (
    <>
    <Layouts>

    <SubHeader banner={banner} heading="Bathing Dates Kumbh Mela - 2025" />

    <section className="blog-details-section">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 m-auto">
                    <div className="bd-text">
                         
                        
                        <div className="bd-more-text">
                        <div className="bd-quote">
                            <h4>BATHING DATES KUMBH MELA - 2025
                            </h4>
                             
                        </div>
                             
                            <p>The Prayagraj (Allahabad) Kumbh Mela 2025 start from January 13, 2025 (the day of Paush Purnima) and will end on February 26th, 2025 (the day of MahaShivratri). This Kumbh Mela Prayagraj 2025 is happening at the Triveni Sangam in Prayagraj (Allahabad). Here is the MahaKumbh main bathing date for bathing:</p>
                        </div>
                         
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="schedule-section spad bathing-dates">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="section-title">
                                    <h2>Bathing Dates </h2>
                                    <p><span className='txtbg'>Do not miss anything topic about the event</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            {/* <div class="sbath_date_box"> */}
                                <div class="col-lg-5">
                                    <div className='bath_date_box_left'>
                                        <img src={bathimg} />
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div className='bath_date_box_right'>
                                        
                                            <div className=' bath-data'>
                                                <div class="date-tiles  ">
                                                    <div class="tile">
                                                        <div class="day-name">
                                                            PAUSH Purnima
                                                        </div>
                                                        <div class="day-date">
                                                            13
                                                        </div>
                                                        <div class="day-month">
                                                            JANUARY
                                                        </div>
                                                        <div class="day-year">
                                                            2025
                                                        </div>
                                                    </div>
                                                    <div class="tile">
                                                        <div class="day-name">
                                                            MAKAR SANKRANTI
                                                        </div>
                                                        <div class="day-date">
                                                            14
                                                        </div>
                                                        <div class="day-month">
                                                            JANUARY
                                                        </div>
                                                        <div class="day-year">
                                                            2025
                                                        </div>
                                                    </div>
                                                    <div class="tile">
                                                        <div class="day-name">
                                                            MAUNI AMAVASYA
                                                        </div>
                                                        <div class="day-date">
                                                            29
                                                        </div>
                                                        <div class="day-month">
                                                            JANUARY
                                                        </div>
                                                        <div class="day-year">
                                                            2025
                                                        </div>
                                                    </div>
                                                    <div class="tile">
                                                        <div class="day-name">
                                                            BASANT PANCHAMI
                                                        </div>
                                                        <div class="day-date">
                                                            03
                                                        </div>
                                                        <div class="day-month">
                                                            FEBRUARY
                                                        </div>
                                                        <div class="day-year">
                                                            2025
                                                        </div>
                                                    </div>
                                                    <div class="tile">
                                                        <div class="day-name">
                                                            MAGHI Purnima
                                                        </div>
                                                        <div class="day-date">
                                                            12
                                                        </div>
                                                        <div class="day-month">
                                                            FEBRUARY
                                                        </div>
                                                        <div class="day-year">
                                                            2025
                                                        </div>
                                                    </div>
                                                    <div class="tile">
                                                        <div class="day-name">
                                                            MAHA SHIVARATRI
                                                        </div>
                                                        <div class="day-date">
                                                            26
                                                        </div>
                                                        <div class="day-month">
                                                            FEBRUARY
                                                        </div>
                                                        <div class="day-year">
                                                            2025
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                    </div>
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                </section>
                <section class="counter-section bg-gradient"><div class="container"><div class="row"><div class="col-lg-12"><div class="counter-text"><h3>"Kumbh Mela Prayagraj (Allahabad) - 2025 Booking Started, Come OnHurry Up!" <Link to="/booking" class="book-btn blink" href="/">Book Tent</Link></h3></div></div></div></div></section>
    </Layouts>
    
    </>
  )
}

export default Bath