import React, { useLayoutEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation, Link } from 'react-router-dom';

// Import your page components
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Bath from './pages/Bath';
import Booking from './pages/Booking';
import { ToastContainer } from 'react-toastify';
import Privacypolicy from './pages/Privacypolicy';
import TermsConditions from './pages/TermsConditions';
import Refundpolicy from './pages/Refundpolicy';

// Wrapper Component for Scroll-to-Top functionality
const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  return (
    <Router>
      <ScrollToTop> 
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/bath-date" element={<Bath />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/refundpolicy" element={<Refundpolicy />} />
        

        </Routes>
                <ToastContainer />

      </ScrollToTop>
    </Router>
  );
}

export default App;
