import React, { useState, useEffect } from 'react';
import Layouts from './Layouts';
import HomeSlider from '../components/HomeSlider';
import AboutImage from "../images/about-img.png";
import { Link } from 'react-router-dom';
import bathimg from "../images/bath-img.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img3 from '../images/img3.png';
import img4 from '../images/img4.png';
import img5 from '../images/img5.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';
import img10 from '../images/img10.png';
import img11 from '../images/img11.png';

const images = [img9, img10, img11, img1, img2, img3, img4, img5, img6, img7, img8];

const Home = () => {
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imagesLoaded, setImagesLoaded] = useState(false); // Track image loading

    useEffect(() => {

        const preloadImages = () => {
            const promises = images.map((src, index) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = src;
                    img.onload = () => {
                        console.log(`Image ${index} loaded: ${src}`);
                        resolve();
                    };
                    img.onerror = (error) => {
                        console.error(`Failed to load image ${index}: ${src}`, error);
                        reject(error);
                    };
                });
            });

            Promise.all(promises)
                .then(() => {
                    console.log("All images preloaded successfully.");
                    setImagesLoaded(true);
                })
                .catch(() => console.error("Failed to load some images."));
        };


        preloadImages();
    }, []);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        arrows: true,
        touchMove: true, 
        accessibility: false,  
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => { 
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
             
            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
            }, 2000); 
            
        }, 4000);  
     
        return () => clearTimeout(timeoutId);
    }, [currentImageIndex]); 
    

    const openLightbox = (index) => {
        setCurrentImageIndex(index);
        setIsLightboxOpen(true);
 
        setTimeout(() => {
            const lightbox = document.querySelector(".ril__inner");
            if (lightbox) lightbox.focus();
        }, 0);
    };

    const handleImageClick = (index) => {
        openLightbox(index);
 
        const currentSlide = document.querySelector(".slick-current");
        if (currentSlide) {
            currentSlide.removeAttribute("aria-hidden");
        }
    };


    const movePrev = () => {
        setCurrentImageIndex((currentImageIndex + images.length - 1) % images.length);
    };

    const moveNext = () => {
        setCurrentImageIndex((currentImageIndex + 1) % images.length);
    };


    return (
        <>
            <Layouts>
                <HomeSlider />

                <section className="counter-section bg-gradient mb-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="counter-text">
                                    <h3>
                                        "Maha Kumbh Mela Prayagraj (Allahabad) - 2025 Booking Started, Come On Hurry Up!"
                                        <Link to="/booking" className="book-btn blink">Book Tent</Link>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="team-member-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h2>Accommodation</h2>
                                    <p>Explore our range of comfortable accommodations designed for your stay.</p>
                                </div>
                            </div>
                        </div>
                        {imagesLoaded ? ( // Only render slider after images are preloaded
                            <Slider {...sliderSettings} className="member-slider">
                                {images.map((item, index) => (
                                    <div key={index} className="member-item">
                                        <img
                                            src={item}
                                            alt={`Accommodation ${index + 1}`}
                                            onClick={() => handleImageClick(index)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <p>Loading images...</p>
                        )}
                    </div>
                </section>
                {console.log(images[currentImageIndex])}
                {isLightboxOpen && (

                    <Lightbox
                        mainSrc={images[currentImageIndex]}
                        nextSrc={images[(currentImageIndex + 1) % images.length]}
                        prevSrc={images[(currentImageIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => {
                            console.log("Closing lightbox");
                            setIsLightboxOpen(false);
                        }}
                        onMovePrevRequest={() => {
                            movePrev();
                        }}
                        onMoveNextRequest={() => {
                            moveNext();
                        }}
                    />
                )}

            </Layouts>
        </>
    );
};

export default Home;
