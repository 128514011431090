import React from 'react'
import Layouts from './Layouts'
import SubHeader from "../components/SubHeader";
import banner from "../images/about_history.svg";
import { Link } from 'react-router-dom';

const Privacypolicy = () => {
    return (
        <>
            <Layouts>

                <SubHeader banner={banner} heading="Privacy Policy" />


                <section className="about-section spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="term-title">
                                    
                               <h3> Introduction </h3>
                                
                               <p> Welcome to shivayadventure.com. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we handle your personal data when you visit our website and tell you about your privacy rights and protections.</p>

<h3>Important Information and Who We Are </h3>

<p>This privacy policy explains how shivayadventure.comcollects and processes your personal data, including data you provide when signing up for a newsletter, purchasing a product or service, or participating in a competition.</p>

<h3>The Data We Collect About</h3>

<p> You We may collect and use the following types of personal data:</p>

<ul>
<li><b>Identity Data</b> includes first name, last name, username or similar identifier, marital status, title, date of birth, and gender.</li>
<li><b>Contact Data</b> includes billing address, delivery address, email address, and telephone numbers.</li>
<li><b>Financial Data</b> includes bank account and payment card details.</li>
<li><b>Transaction Data</b> includes details about payments to and from you and other details of products and services you have purchased from us.</li>
<li><b>Technical Data</b> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</li>
</ul> 

<h3>How We Use Your Personal Data</h3>
<p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
<ul>
<li>To register you as a new customer.</li>
<li>To process and deliver your order including managing payments, fees, and charges.</li>
<li>To manage our relationship with you.</li>
<li>To enable you to partake in a prize draw, competition, or complete a survey.</li>
<li>To administer and protect our business and this website.</li>
</ul>
<h3>Cookies</h3>
<p>We use cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. For detailed information on the cookies we use, the purposes for which we use them, and how you can manage them, please see our Cookie Policy.</p>

<h3>Sharing Your Personal Data
</h3>
<p>We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above.

</p>
<ul>
    <li>External Third Parties such as service providers acting as processors who provide IT and system administration services.</li>
    <li>Professional advisers including lawyers, bankers, auditors, and insurers who provide consultancy, banking, legal, insurance, and accounting services.</li>
    <li>Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets.</li>
</ul>
<h3>Data Security
</h3>
<p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered, or disclosed but we cannot guarantee these measures are foolproof. If you have any questions regarding this, please write a mail to us at infoshivayadventure.com

</p>
<h3>Your Legal Rights
</h3>
<p>Under certain circumstances, you have rights under data protection laws in relation to your personal data including the right to access, correct, erase, object to processing, and request the transfer of your personal data.

</p>
<h3>Changes to the Privacy Policy and Your Duty to Inform Us of Changes
</h3>
<p>We keep our privacy policy under regular review. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.

</p>
<h3>Cookie Policy
</h3>
<p>For detailed information on the cookies we use and the purposes for which we use them, see our Cookie Policy. This privacy policy has been designed to be clear and concise to meet the requirements of various data protection laws, including GDPR, and to ensure transparency with our website users.

</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>


            </Layouts>
        </>
    )
}

export default Privacypolicy


 