import React from 'react'
import Layouts from './Layouts'
import SubHeader from "../components/SubHeader";
import banner from "../images/about_history.svg";

const Refundpolicy = () => {
    return (
        <>
            <Layouts>

                <SubHeader banner={banner} heading="Refunds & Cancellation Policy" />


                <section className="about-section spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="term-title">
                                    <h3>Introduction</h3>
                                    <p className="f-para">At shivayadventure.com, we understand that plans can change, and we aim to be as flexible as possible with our cancellation and refund policy. This policy applies to all tour packages sold through our website.</p>
                                    <h3>Cancellation by the Customer  </h3>
                                    <p>If you need to cancel your booking, please inform us as soon as possible. The following cancellation fees will apply, based on the number of days before your scheduled arrival:</p>
<ul>
<li>If cancelled before 1st December 2024, 100% refund will be processed & credited.</li>
<li>If cancelled between 1st December 2024 to 31st December 2024, 75% refund will be processed & credited.</li>
<li>If cancelled between 1st January 2025 to 15th January 2025 50% refund will be processed & credited.</li>
<li>If cancelled after 15th January 2025, no refund will be processed & credited.</li>
</ul>

<p className='red-note'>Note: Individuals travelling from Canada, Gulf countries, or nations currently engaged in conflict or with strained geopolitical relations with India are eligible for a full refund until December 15th. However, please note that an additional charge of 10% above the standard rates will apply at the time of booking, and we should be informed beforehand at the time of booking. For further information or assistance, feel free to contact us.</p>
 
<h3>Changes to Bookings</h3>
<p>If you wish to change your booking in any way, we will do our best to accommodate your requests. However, changes are subject to availability and any additional costs incurred due to the change.</p>

<h3>Cancellation by Us</h3>
<p>In the unlikely event that we must cancel your tour package due to unforeseen circumstances, we will offer you the choice of:</p>
<ul>
<li>A full refund of all monies paid, or</li>
<li>An alternative tour package of comparable standard, subject to availability.</li>
</ul>
<h3>Refund Processing</h3>
<p>Refunds will be processed & credited within 30 to 60 days of the cancellation request. The refund will be made using the original payment method, unless otherwise requested by the customer.</p>
    
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </section>


            </Layouts>
        </>
    )
}

export default Refundpolicy